import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentText from '@solid-ui-components/ContentText'
import Divider from '@solid-ui-components/Divider'
import Reveal from '@solid-ui-components/Reveal'
import React from 'react'
import { Box, Container, Flex, css } from 'theme-ui'
import { Link } from 'gatsby'
import ContentButtons from '@solid-ui-components/ContentButtons'
const styles = {
  listItem: {
    //   alignItems: `stretch`,
    justifyContent: `flex-start`
  },
  line: {
    position: `absolute`,
    bottom: `10px`,
    left: `50%`,
    width: `1px`,
    height: t => `calc(100% - ${t.sizes.icon.md + 20}px)`,
    transform: `translateX(-50%)`,
    zIndex: 1,
    bg: `omega`
  },
  number: {
    color: `white`,
    fontWeight: `body`,
    borderRadius: `full`,
    bg: `alpha`,
    size: `icon.md`,
    textAlign: `center`,
    p: 2,
    mb: 0
  }
}

const FeaturesBlock03 = ({ content: { collection } }) => (
  <Container>
    <Flex sx={{ flexDirection: [`column`], m: [0, -4] }}>
      {collection.map(({ text, images, buttons }, index) => {
        return (
          <Reveal
            key={`item-${index}`}
            effect='fadeInDown'
            delay={0.2 * (index + 1)}
            css={css({
              flexBasis: [`1`, null, `1`, `1`]
            })}
          >
            <Flex sx={styles.listItem}>
              <Box
                sx={{
                  position: `relative`,
                  flexDirection: 'row',
                  width: '50%'
                  //   flexShrink: 0
                  //   mr: 4
                }}
              >
                <Link
                  to={
                    buttons[0].link
                    //  '/blog/Blog01?title=Why-Tailor-Your-Own-E-commerce-Platform?'
                  }
                >
                  <ContentImages
                    content={{ images }}
                    imagePosition='center'
                    threshold={0.1}
                  />
                </Link>
                <Divider space={3} />
              </Box>
              <Box
                sx={{
                  textAlign: `left`,
                  width: '50%',
                  pl: 5,
                  alignItems: 'center',
                  justifyContent: 'center'

                  //   flexShrink: 0
                  //   mr: 4
                }}
              >
                <ContentText content={text} />
                {buttons && <ContentButtons content={buttons} />}
              </Box>
            </Flex>
          </Reveal>
        )
      })}
    </Flex>
  </Container>
)

export default WithDefaultContent(FeaturesBlock03)
